import { Link, graphql } from "gatsby";
import React, { FC } from "react";
import { Kenro2PaneLayout } from "~components/templates/kenro-2pane-layout/page";
import { SEO } from "~components/templates/seo";
import { Button } from "~components/utils/button";
import * as styles from "./index.module.scss";

type Props = {
  data: GatsbyKenroThankyouWebinarPageQuery;
};

const Component: FC<Props> = () => {
  return (
    <>
      <SEO meta={[{ name: "robots", content: "noindex" }]} />
      <Kenro2PaneLayout>
        <div className={styles.Thankyou}>
          <div className={styles.Thankyou_Container}>
            <h1 className={styles.Thankyou_Heading}>
              ウェビナーへお申込みありがとうございます
            </h1>
            <p className={styles.Thankyou_Message}>
              ウェビナーの視聴URLにつきましては、メールにてご案内させていただきます。
              <br />
              URLのご案内メールが届かない方はお手数ですが contact@flatt.tech
              までご連絡ください。
            </p>
            <Link to="/kenro">
              <Button buttonTheme="Kenro">KENRO トップページへ</Button>
            </Link>
          </div>
        </div>
      </Kenro2PaneLayout>
    </>
  );
};

export const query = graphql`
  query KenroThankyouWebinarPage {
    logo_color: file(relativePath: { eq: "kenro/logo_color.svg" }) {
      publicURL
    }
  }
`;

export default Component;
